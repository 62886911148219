import React from "react";
import { Link } from "gatsby";
import SectionHeader from "../components/SectionHeader";
import Layout from "../components/Layout";

const AboutPage = () => {
  return (
    <Layout>
      <div className="py-20 bg-black-200 min-h-screen px-5 md:px-20 text-white">
        <div className="text-gray-400">
          <Link to="/" className="underline hover:text-white">
            Home
          </Link>{" "}
          / Services / About Us
        </div>
        <div className="mt-10">
          <SectionHeader color="white" title="About Us" />
        </div>
        <div className="mt-10 flex flex-col gap-5 md:px-[10%]">
          <div className="flex justify-center items-center">
            <img
              alt="penetration-testing"
              src="/images/cyber-security-tools.webp"
              width="1000px"
            />
          </div>
          <p>
            At RED HORSE SECURITY, we are dedicated to providing top-notch
            cybersecurity solutions that empower organizations to protect their
            digital assets in an increasingly complex threat landscape. With a
            team of seasoned experts and a comprehensive suite of services, we
            are committed to helping businesses of all sizes secure their
            networks, data, and systems from cyber threats.
          </p>
          <h6 className="font-black text-xl uppercase">Our Mission</h6>
          <p>
            Our mission is to deliver cutting-edge cybersecurity services that
            enable our clients to stay ahead of evolving threats. We believe in
            a proactive approach to security, combining advanced technology,
            expert knowledge, and best practices to safeguard your
            organization’s most valuable assets. Our goal is to build a safer
            digital world where businesses can operate with confidence and
            resilience.
          </p>
          <h6 className="font-black text-xl uppercase">Our Vision</h6>
          <p>
            We envision a world where cybersecurity is not an afterthought but
            an integral part of every organization’s strategy. We strive to be
            at the forefront of the cybersecurity industry, continuously
            innovating and adapting to new challenges. By fostering a culture of
            security awareness and education, we aim to create a safer digital
            environment for everyone.
          </p>
          <h6 className="font-black text-xl uppercase">Our Expertise</h6>
          <p>
            With years of experience in the cybersecurity field, our team of
            experts brings a wealth of knowledge and skills to every project.
            Our specialties include:
          </p>
          <ul className="list-disc ml-10">
            <li>
              <p>
                <span className="font-bold">Customized Solutions:</span>{" "}
                Identifying vulnerabilities in your systems before attackers do.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Red Team as a Service:</span>{" "}
                Simulating real-world attacks to test and improve your defenses.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Cyber Security Consultancy:</span>{" "}
                Providing strategic guidance and solutions tailored to your
                unique needs.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">
                  Cyber Security Tools Development:
                </span>{" "}
                Developing innovative tools to enhance your security measures.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">OSINT Analytics:</span> Leveraging
                open-source intelligence to detect and mitigate potential
                threats.
              </p>
            </li>
          </ul>
          <h6 className="font-black text-xl uppercase">Our Approach</h6>
          <ul className="list-decimal ml-10">
            <li>
              <p>
                <span className="font-bold">Client-Centric:</span> We prioritize
                your needs and tailor our services to meet your specific
                requirements. Our solutions are designed to be flexible and
                scalable, ensuring they grow with your business.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Proactive Security:</span> We focus
                on proactive measures to prevent cyber incidents before they
                occur. By staying ahead of emerging threats, we help you build a
                robust defense against potential attacks.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Comprehensive Solutions:</span> Our
                range of services covers all aspects of cybersecurity, from
                assessment and planning to implementation and monitoring. This
                holistic approach ensures that no aspect of your security is
                overlooked.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Continuous Improvement:</span>{" "}
                Cybersecurity is an ongoing process. We continuously evaluate
                and enhance our strategies to adapt to the ever-changing threat
                landscape. Our commitment to improvement ensures that you
                receive the most up-to-date and effective security solutions.
              </p>
            </li>
          </ul>
          <h6 className="font-black text-xl uppercase">Why Choose Us?</h6>
          <ul className="list-disc ml-10">
            <li>
              <p>
                <span className="font-bold">Experienced Professionals:</span>{" "}
                Our team comprises industry veterans with deep expertise in
                cybersecurity. They are passionate about what they do and
                dedicated to protecting your organization.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Red Team as a Service:</span>{" "}
                Simulating real-world attacks to test and improve your defenses.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Cutting-Edge Technology:</span> We
                utilize the latest technologies and methodologies to provide you
                with the best possible protection. Our tools and techniques are
                at the forefront of the cybersecurity industry.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Proven Track Record:</span> We have
                a history of successfully helping businesses strengthen their
                security posture. Our clients trust us to deliver reliable and
                effective solutions.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Customer Satisfaction:</span> We are
                committed to building long-term relationships with our clients.
                Your satisfaction is our top priority, and we go above and
                beyond to ensure you are fully protected and satisfied with our
                services.
              </p>
            </li>
          </ul>
          <h6 className="font-black text-xl uppercase">Our Values</h6>
          <ul className="list-disc ml-10">
            <li>
              <p>
                <span className="font-bold">Integrity:</span> We conduct our
                business with the highest level of integrity, ensuring that we
                always act in the best interests of our clients.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Innovation:</span> We are constantly
                seeking new ways to improve our services and stay ahead of the
                curve. Innovation is at the heart of everything we do.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Excellence:</span> We strive for
                excellence in every aspect of our work, from the quality of our
                services to the expertise of our team.
              </p>
            </li>
            <li>
              <p>
                <span className="font-bold">Collaboration:</span> We believe in
                working closely with our clients to understand their needs and
                provide tailored solutions. Collaboration is key to our success.
              </p>
            </li>
          </ul>
          <p>
            At RED HORSE SECURITY, we are more than just a cybersecurity
            provider – we are your trusted partner in navigating the digital
            landscape. Let us help you secure your future and achieve peace of
            mind with our comprehensive cybersecurity solutions.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
